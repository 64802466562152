import ActionType from 'darwin/actions/ActionType';

const initialState = {fetching: false, fetchingMore: false, tulips: null, numTulipsRemaining: null, totalNumOwnedTulips: null};

export default function reducer(state = initialState, action = {}) {
    let newState = {
        ...state,
    };
    if (state.tulips) {
        newState.tulips = [...state.tulips];
    }

    switch (action.type) {
        case ActionType.REQUEST_NUM_OWNED_TULIPS:
            newState.totalNumOwnedTulips = null;
            break;
        case ActionType.RECEIVE_NUM_OWNED_TULIPS:
            newState.totalNumOwnedTulips = action.numTulips.toBigNumberJs();
            break;
        case ActionType.REQUEST_MORE_OWNED_TULIPS:
            newState.fetchingMore = true;
            break;
        case ActionType.RECEIVE_MORE_OWNED_TULIPS:
            if (!newState.tulips) {
                newState.tulips = [];
            }
            while (newState.tulips.length < action.offset) {
                newState.tulips.push(null);
            }
            // now length >= offset
            for (let i = 0; i < action.tulips.length; i++) {
                let ind = i + action.offset;
                if (ind >= newState.tulips.length) {
                    newState.tulips.push(action.tulips[i]);
                } else {
                    newState.tulips[ind] = action.tulips[i];
                }
            }
            newState.numTulipsRemaining = action.numRemaining.toBigNumberJs();
            newState.fetchingMore = false;
            break;
        case ActionType.REQUEST_MORE_OWNED_TULIPS_FAILED:
            newState.fetchingMore = false;
            break;
        case ActionType.REQUEST_OWNED_TULIPS:
            newState.fetching = true;
            break;
        case ActionType.RECEIVE_OWNED_TULIPS:
            newState.tulips = action.tulips;
            newState.numTulipsRemaining = 0;
            newState.fetching = false;
            break;
        case ActionType.REQUEST_OWNED_TULIPS_FAILED:
            newState.fetching = false;
            break;
        case ActionType.TRANSFER_TULIP_REQUEST:
            break;
        case ActionType.TRANSFER_TULIP_SUCCESS:
            let ind = 0;
            for (let i = 0; i < newState.tulips.length; i++) {
                if (newState.tulips[i].id.isEqualTo(action.id)) {
                    ind = i;
                    break;
                }
            }
            newState.tulips.splice(ind, 1);
            break;
        case ActionType.TRANSFER_TULIP_FAILED:
            break;
    }
    return newState;
}