import React, { Component } from 'react';
import { connect } from 'react-redux';

import Constants from 'darwin/Constants';
import Actions from 'darwin/actions';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';
import NoTulipIndicator from 'darwin/components/NoTulipIndicator'
import MyTulipsGrid from 'darwin/components/MyTulipsGrid';
import TulipInfoModal from 'darwin/components/TulipInfoModal';
import SendTulipModal from 'darwin/components/SendTulipModal';

import Api from 'darwin/lib/api';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

const Styles = {
    container: {
        margin: '24px 0',
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerSortDropdown: {

    },
    spacer: {
        flex: 1,
    },
};

class MyTulipsScene extends Component {
    static KEY = 'mytulips';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.containerRef = null;

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.state = {
            showingModalFor: null,
            showingSendModalFor: null,
            sortBy: null,
        };
    }

    componentWillMount() {
        document.title = 'EtherTulips: My Tulips';
        window.scene = this;
        if (!this.props.tulips) {
            this._dispatch(Actions.fetchMoreOwnedTulips(0));
        }
        if (this.props.totalNumOwnedTulips === null) {
            this._dispatch(Actions.fetchNumOwnedTulips());
        }

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        if (!this.props.tulips) {
            return this._renderLoading();
        }
        if (this.props.tulips.length == 0) {
            return this._renderNoTulips();
        }

        let notice = null;
        let params = new URLSearchParams(this.props.location.search);
        if (params.get('purchased') && !isNaN(params.get('purchased'))) {
            notice = (
                <div style={Styles.noticeFlexContainer}>
                    <div style={Styles.notice}><span>You just grew {params.get('purchased')} tulips!</span></div>
                </div>
            );
        }

        let header = this._renderHeader();
        let body = this.props.isLoading
            ? (
                <div style={{marginTop: 64}}>{this._renderLoading()}</div>
            )
            : (
                <MyTulipsGrid
                    tulips={this.props.tulips}
                    onCellSelected={(tulip, appearance, battle) => this.setState({showingModalFor: {tulip, appearance, battle}})}
                    onCellSendClicked={(tulipId, appearance) => this.setState({showingSendModalFor: {tulipId, appearance}})}
                    sortBy={this.state.sortBy} />
            );

        return (
            <div style={Styles.container} ref={ref => this.containerRef = ref}>
                {
                    this.state.showingModalFor !== null
                    && (
                        <div>
                            <TulipInfoModal
                                onRequestClose={() => this.setState({showingModalFor: null})}
                                tulip={this.state.showingModalFor.tulip}
                                appearance={this.state.showingModalFor.appearance}
                                battle={this.state.showingModalFor.battle}
                            />
                        </div>)
                }
                {
                    this.state.showingSendModalFor !== null
                    && (
                        <div>
                            <SendTulipModal
                                onRequestClose={() => this.setState({showingSendModalFor: null})}
                                forTulip={this.state.showingSendModalFor}
                                dispatch={this._dispatch}
                            />
                        </div>
                    )
                }
                {header}
                {notice}
                {body}
            </div>
        );
    }

    _renderHeader() {
        let numTulipsLabel = this.props.totalNumOwnedTulips === null
            ? <span><span className="fa fa-spinner" style={{color: '#555'}}></span></span>
            : <span>{this.props.totalNumOwnedTulips.toString()} tulips</span>;
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>My Tulips</span>
                    <div style={Styles.spacer}></div>
                    <div style={Styles.headerRight}>
                        {numTulipsLabel}&nbsp;&nbsp;&middot;&nbsp;&nbsp;Sort by{' '}
                        <select style={Styles.headerSortDropdown} onChange={event => { this._dispatch(Actions.fetchOwnedTulips()); this.setState({sortBy: event.target.value})}}>
                            <option value="none">Default</option>
                            <option value="new">New</option>
                            <option value="old">Old</option>
                            <option value="rarity">Rarity</option>
                            <option value="bp">BP</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }
    
    _renderNoTulips() {
        return <NoTulipIndicator />;
    }

    _onScroll() {
        if (this.props.isLoading || this.props.isFetchingMore) {
            return;
        }
        if (this.props.numTulipsRemaining && this.props.numTulipsRemaining.isGreaterThan(0)) {
            if (this.containerRef) {
                let distance = this.containerRef.getBoundingClientRect().bottom - window.innerHeight;
                if (distance < Constants.myTulipsPaginationFetchMoreDistanceThreshold) {
                    this._dispatch(Actions.fetchMoreOwnedTulips(this.props.tulips.length));
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ownedTulips.fetching,
        isFetchingMore: state.ownedTulips.fetchingMore,
        tulips: state.ownedTulips.tulips,
        totalNumOwnedTulips: state.ownedTulips.totalNumOwnedTulips,
        numTulipsRemaining: state.ownedTulips.numTulipsRemaining,
    };
};

let apiRequiredScene = sceneRequiresApi(MyTulipsScene);
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
