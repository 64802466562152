import React, { Component } from 'react';

import Api from 'darwin/lib/api';

import MetaMaskNux from 'darwin/components/MetaMaskNux';
import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

const API_STATUS_LOADING = 0;
const API_STATUS_LOADED = 1;
const API_STATUS_FAILED = -1;

export default function sceneRequiresApi(SceneComponent) {
    return class extends Component {
        static KEY = SceneComponent.KEY;

        constructor(props) {
            super(props);

            this.state = {
                apiStatus: API_STATUS_LOADING,
            };
        };

        componentWillMount() {
            Api.ready()
                .then(() => {
                    this.setState({
                        apiStatus: API_STATUS_LOADED,
                    });
                })
                .catch(() => {
                    this.setState({
                        apiStatus: API_STATUS_FAILED,
                    });
                });
        }

        render() {
            let content = null;
            if (this.state.apiStatus === API_STATUS_LOADING) {
                content = this._renderLoading();
            } else if (this.state.apiStatus === API_STATUS_LOADED) {
                content = this._renderScene();
            } else if (this.state.apiStatus === API_STATUS_FAILED) {
                content = this._renderError();
            }
            return content;
        }

        _renderLoading() {
            return (
                <SceneLoadingIndicator />
            );
        }

        _renderScene() {
            return (
                <SceneComponent {...this.props} />
            );
        }

        _renderError() {
            return (
                <MetaMaskNux />
            );
        }
    }
}