import React, { Component } from 'react';
var Styles = {
    calloutContainer: {
        display: 'flex',
        padding: 32,
        flexDirection: 'row',
    },
    calloutHead: {
    },
    calloutItem: {
        flex: 1,
        padding: 24,
    },
};

export default class LandingCallout extends Component {
    render() {
        return (
            <div style={Styles.calloutContainer}>
                <div style={Styles.calloutItem}>
                    <h3 style={Styles.calloutHead}>
                        <span className="fa fa-key"></span> Own your tulips
                    </h3>
                    <p>
                        Your tulips are yours on the Ethereum blockchain as <a href="https://github.com/ethereum/eips/issues/721">ERC-721 crypto-collectible tokens</a>.
                        Each one is unique, and just like anything else you own, you can sell and trade them with others!
                    </p>
                </div>

                <div style={Styles.calloutItem}>
                    <h3 style={Styles.calloutHead}>
                        <span className="fa fa-hand-rock-o"></span> Battle your tulips
                    </h3>
                    <p>
                        Yep, you can battle your tulips because why not? Select a squad of your favorite tulips and show everyone what you've got!
                    </p>
                </div>

                <div style={Styles.calloutItem}>
                    <h3 style={Styles.calloutHead}>
                        <span className="fa fa-clock-o"></span> Time is ticking
                    </h3>
                    <p>
                        Just like Tulip Mania, prices are increasing exponentially ~50% a day! 
                    </p>
                </div>
            </div>
        );
    }
}
