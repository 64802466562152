import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Slider from 'react-rangeslider';

import BigNumber from 'bignumber.js';
import {ethers} from 'ethers';

import Actions from 'darwin/actions';

import ShopTimeRemaining from 'darwin/components/ShopList/ShopTimeRemaining';
import ShopItemRow from 'darwin/components/ShopList/ShopItemRow';
import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

import Constants from 'darwin/Constants';
import Strings from 'darwin/Strings';
import genInfo from 'darwin/lib/tulips/genInfo';
import Api from 'darwin/lib/api';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import getAsset from 'darwin/lib/assets/getAsset';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import 'react-rangeslider/lib/index.css';

var Styles = {
    itemPriceLine: {
        marginTop: 8,
    },
    itemEthSymbol: {
        fontSize: 20,
    },
    container: {
        marginTop: 24,
        marginBottom: 8,
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    priceCountdownTimer: {
        color: 'red',
    },
    spacer: {
        flex: 1,
    },
    shopFlexContainer: {
        marginTop: 32,
        maxWidth: 1024,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 32,
        paddingRight: 32,
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
    },
    genContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    genHeader: {
        paddingBottom: 8,
        marginBottom: 24,
        borderBottom: '2px solid #888',
    },
    genHeaderTitle: {
        fontSize: 24,
    },
    genComingSoonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 40,
    },
    genComingSoonHeader: {
        fontSize: 48,
    },
    genComingSoonSubhead: {
        fontSize: 16,
    },
    genComingSoonDescription: {
        maxWidth: 512,
        marginBottom: 16,
        textAlign: 'center',
        fontStyle: 'italic',
        color: '#777',
    },
    genComingSoonSeedImage: {
        width: 256,
        height: 256,
    },
    genItems: {
        display: 'flex',
        flexDirection: 'column',
    },
    seedModalContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '24px 0',
    },
    seedModalClose: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    seedModalContent: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    seedModalSeedImage: {
        width: 304,
        height: 304,
    },
    seedModalDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: '24px 0',
    },
    seedModalCloseButton: {
        cursor: 'pointer'
    },
    splashButton: {
        backgroundColor: '#F92A82',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    itemPriceLine: {
        marginTop: 8,
    },
    itemName: {
        fontSize: 20,
    },
    itemDescription: {
        marginTop: 8,
        fontStyle: 'italic',
        color: '#777',
        marginBottom: 8,
    },
    itemLegalAgreement: {
        textAlign: 'center',
    },
    itemPurchasing: {
        textAlign: 'center',
    },
    itemPurchaseError: {
        textAlign: 'center',
        color: 'red',
    },
    itemNote: {
        textAlign: 'center',
        fontSize: 10,
    },
    loadingSpinner: {
        height: 16,
    },
    disclaimerMessage: {
        fontSize: 14,
        borderRadius: 5,
        marginBottom: 24,
        padding: '.75rem 1.25rem',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '2px solid #b8daff',
        flex: 1,
    },
};

class ShopScene extends Component {
    static KEY = 'shop';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.state = {
            seedModalOpenFor: null,
            buySeedModalAmount: 1,
            intervalHandle: null,
            purchasing: false,
        };
    }

    componentWillMount() {
        document.title = 'EtherTulips: Shop';
        if (!this.props.gens) {
            this._dispatch(Actions.fetchShopInfoForGen(1));
            this._dispatch(Actions.fetchShopInfoForGen(0));
        }
    }

    render() {
        if (this.props.isLoading || !this.props.gens || !this.props.gens[0]) { // TODO: this is a hack to ensure price info is loaded
            return this._renderLoading();
        } else {
            return this._renderShop();
        }
    }

    _renderShop() {
        let {price, futurePrice, blocksRemaining, changeBlock} = this._getPricingForGen(0);
        let modal = this.state.seedModalOpenFor !== null && this._renderModal();

        return (
            <div style={Styles.container}>
                { this._renderHeader(price, futurePrice, blocksRemaining, changeBlock) }
                <div style={Styles.shopFlexContainer}>
                    <span style={Styles.disclaimerMessage}>
                        As a reminder, Ether Tulips are <strong>not</strong> investments and are provided for entertainment purposes only. Please review the <a href="/legal/terms" target="_blank" style={{textDecoration: 'underline'}}>Terms of Service</a> for more information.
                    </span>
                    {this._hasGen(1) && this._renderGen(1)}
                    {this._hasGen(0) && this._renderGen(0)}
                </div>
                {modal}
            </div>
        );
    }

    _renderHeader(price, futurePrice, blocksRemaining, changeBlock) {
        let priceIncreaseView = null;
        if (blocksRemaining) {
            let increasePercentage = Math.ceil(futurePrice.dividedBy(price).minus(1).times(100));
            priceIncreaseView = (
                <span>
                    Prices increase{' '}
                    <strong>{increasePercentage.toString()}%</strong>{' '}
                    in <span style={Styles.priceCountdownTimer}><ShopTimeRemaining blocksRemaining={blocksRemaining} /></span> (at block {changeBlock.toString()}).
                </span>
            );
        }
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>Shop</span>
                    <div style={Styles.spacer}></div>
                </div>
            </div>
        );
    }

    _renderGen1ComingSoon() {
        return (
            <div style={Styles.genComingSoonContainer}>
                <div style={Styles.genComingSoonHeader}><span>Generation 1: ???</span></div>
                <div style={Styles.genComingSoonSubhead}>Coming soon.</div>
                <div>
                    <img src={getAsset("seeds/1.png")} style={Styles.genComingSoonSeedImage} />
                </div>
                <div style={Styles.genComingSoonDescription}>
                    <span>{Strings.shopGen1SeedDescription}</span>
                </div>
                <div>
                    <a href="https://medium.com/@ethertulips/introducing-gen-1-a-sneak-peek-c4ad2531075d" target="_blank" className="hoverUnderline">Get a sneak peak</a>
                </div>
            </div>
        );
    }

    _renderGen(gen) {
        let {price, futurePrice, blocksRemaining, changeBlock} = this._getPricingForGen(gen);
        let {name, seedName, seedDescription, learnMoreUrl} = genInfo[gen];

        let displayPrice = this._getDisplayPrice(price);
        let displayFutureEthPrice = this._getDisplayPrice(futurePrice);

        return (
            <div style={Styles.genContainer}>
                <div style={Styles.genHeader}><span style={Styles.genHeaderTitle}>Generation {gen}: {name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={learnMoreUrl} target="_blank" className="hoverUnderline">Learn more</a></div>
                <div style={Styles.genItems}>
                    <ShopItemRow
                        name={seedName}
                        description={seedDescription}
                        price={displayPrice}
                        futurePrice={displayFutureEthPrice}
                        blocksRemaining={blocksRemaining}
                        changeBlock={changeBlock}
                        onSelect={() => this.setState({seedModalOpenFor: gen})}
                        imageUrl={getAsset("seeds/"+gen+".png")}
                        imageBackgroundColor="#eee" />
                </div>
            </div>
        );
    }

    _renderGen0(price, futurePrice, blocksRemaining, changeBlock) {
        return (
            <div style={Styles.genContainer}>
                <div style={Styles.genHeader}><span style={Styles.genHeaderTitle}>Generation 0: Origins</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://medium.com/@ethertulips/origins-generation-0-tulips-249a99b02203" target="_blank" className="hoverUnderline">Learn more</a></div>
                <div style={Styles.genItems}>
                    <ShopItemRow
                        name="A Humble Seed"
                        description={Strings.shopGen0SeedDescription}
                        price={price}
                        futurePrice={futurePrice}
                        blocksRemaining={blocksRemaining}
                        changeBlock={changeBlock}
                        onSelect={() => this.setState({seedModalOpenFor: 0})}
                        imageUrl={getAsset("seeds/0.png")}
                        imageBackgroundColor="#eee" />
                </div>
            </div>
        );
    }

    _renderModal() {
        if (this.state.seedModalOpenFor === null) {
            return null;
        }

        let gen = this.state.seedModalOpenFor;
        let {seedName, seedDescription} = genInfo[gen];
        let {price} = this._getPricingForGen(gen);
        let ethPrice = this._getDisplayPrice(price);

        let purchaseButton;
        let purchaseError = null;
        if (!this.state.purchasing) {
            purchaseButton = (
                <button style={Styles.splashButton} onClick={() => {
                    let totalCharge = price.times(this.state.buySeedModalAmount);
                    this.setState({
                        purchasing: true,
                        purchaseError: null,
                    });
                    this._dispatch(Actions.buyTulips(this.state.buySeedModalAmount, gen, totalCharge))
                        .then(resp => {
                            if (resp) {
                                this.setState({ seedModalOpenFor: null, purchasing: false });
                                this.props.history.push(
                                    '/shop/purchased?purchased=' +
                                    this.state.buySeedModalAmount +
                                    '&transactionHash='+
                                    resp.hash
                                );
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                purchasing: false,
                                purchaseError: error,
                            });
                        });
                }}>
                    { !this.state.purchasing ? "Buy" : "Loading..." }
                </button>
            );
        } else {
            purchaseButton = (
                <span style={Styles.itemPurchasing}><img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />&nbsp;Waiting for network...(may take a few minutes)</span>
            );
        }

        if (this.state.purchaseError) {
            purchaseError = (
                <span style={Styles.itemPurchaseError}>Transaction error.</span>
            );
        }

        return (
            <Modal
                onRequestClose={() => this.setState({seedModalOpenFor: null})}
                style={{
                    content: {
                        borderRadius: '4px',
                        bottom: 'auto',
                        minHeight: '10rem',
                        left: '50%',
                        padding: '2rem',
                        position: 'fixed',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                        minWidth: '20rem',
                        width: '80%',
                        maxWidth: '60rem'
                    }
                }}
                isOpen={this.state.seedModalOpenFor !== null}>
                <div style={Styles.seedModalContainer}>
                    <div style={Styles.seedModalClose}>
                        <div style={{flex: 1}}></div>
                        <div style={Styles.seedModalCloseButton} onClick={() => this.setState({seedModalOpenFor: null})}>Close</div>
                    </div>
                    <div style={Styles.seedModalContent}>
                        <div>
                            <img style={Styles.seedModalSeedImage} src={getAsset("seeds/"+gen+".png")} />
                        </div>
                        <div style={Styles.seedModalDetails}>
                            <div style={Styles.itemName}>{seedName}</div>
                            <div style={Styles.itemPriceLine}>
                                <span className="fa fa-tag" style={{color: '#FF7F50'}}></span>&nbsp;&nbsp; <strong><span style={Styles.itemEthSymbol}>⟠</span> {ethPrice.toString()}</strong>
                                &nbsp;&nbsp;&nbsp; Quantity: {this.state.buySeedModalAmount} &nbsp;&nbsp; Total Cost: <span style={Styles.itemEthSymbol}>⟠</span>&nbsp;{ethPrice.times(this.state.buySeedModalAmount).toString()}
                            </div>
                            <div style={Styles.itemDescription}>{seedDescription}</div>
                            <Slider
                                value={this.state.buySeedModalAmount}
                                orientation="horizontal"
                                onChange={val => this.setState({buySeedModalAmount: val})}
                                min={1}
                                max={25}
                            />
                            <span style={Styles.itemLegalAgreement}>By purchasing, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.</span>
                            <br />
                            {purchaseError}
                            {purchaseButton}
                            <br />
                            <span style={Styles.itemNote}>Note: Total cost does not include transaction fees. For gas, we recommend using the MetaMask gas limit recommendation and <a href="https://ethgasstation.info/" className="hoverUnderline" target="_blank">a suitable gas price</a>.</span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    _getDisplayPrice(amt) {
        const displayPrice = amt.dividedBy(ethers.constants.WeiPerEther.toString());
        return displayPrice.decimalPlaces(3, BigNumber.ROUND_UP);
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }

    _renderError() {

    }

    _hasGen(gen) {
        return !!this.props.gens[String(gen)];
    }

    _getPricingForGen(gen) {
        if (!this.props.gens[String(gen)]) {
            return null;
        }
        return this.props.gens[String(gen)];
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.shop.fetching,
        gens: state.shop.gens,
    };
};

let apiRequiredScene = sceneRequiresApi(ShopScene)
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
