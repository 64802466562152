import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';
import getAsset from 'darwin/lib/assets/getAsset';
import typeToColors  from 'darwin/lib/duel/typeToColors';

import TulipImage from 'darwin/components/TulipImage';

var Styles = {
    container: {
        display: 'flex',
        position: 'relative',
    },
    rightContainer: {
        top: 120,
    },
    leftContainer: {
        top: 40,
    },
    statusWrapper: {
        position: 'relative',
    },
    rightStatusWrapper: {
        top: -40,
        right: -15,
    },
    leftStatusWrapper: {
        top: -30,
        left: -30,
    },
    statusContainer: {
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        padding: 12,
        borderRadius: 10,
        width: 192,
    },
    speciesLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    typeIndicator: {
        fontSize: 10,
        borderRadius: 4,
        padding: 2,
        color: '#fff',
        marginLeft: 4,
    },
    healthBarOuter: {
        backgroundColor: '#eee',
        width: '100%',
        borderRadius: 2,
    },
    healthBarInner: {
        backgroundColor: '#4caf50',
        height: 5,
        borderRadius: 2,
    },
    statsRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tulipImageContainer: {
        position: 'relative',
    },
};

export default class DuelTulip extends Component {
    render() {
        let petal = this.props.appearance[0];
        let stem = this.props.appearance[1];
        let face = this.props.appearance[2];
        let rarity = this.props.appearance[3];
        let species = this.props.appearance[4];
        let gen = this.props.appearance[5];

        let bp = this.props.battleStats[0];
        let damage = this.props.battleStats[1];
        let armor = this.props.battleStats[2];
        let strength = this.props.battleStats[3];
        let agility = this.props.battleStats[4];
        let intelligence = this.props.battleStats[5];
        let type = this.props.type;

        let maxHealth = this.props.tulipState.maxHp;
        let remainingHealth = this.props.tulipState.hp;
        let healthStyle = { width: (remainingHealth / maxHealth * 100).toString() + '%' };

        let statusComponent =
            <div style={Styles.statusContainer}>
                <div style={Styles.speciesLine}><div>{species}</div><div style={{flex: 1}} /><span style={mergeStyles(Styles.typeIndicator, {backgroundColor: typeToColors[type][0], border: '1px solid #'+typeToColors[1]})}>{type}</span></div>
                <div style={Styles.healthBarOuter}>
                    <div style={mergeStyles(Styles.healthBarInner, healthStyle)}></div>
                </div>
                <div style={Styles.statsRow}>
                    <div style={Styles.bp}>{bp} BP</div>
                    <div style={Styles.health}>{remainingHealth}/{maxHealth} HP</div>
                </div>
            </div>;

        let result;
        if (this.props.alignRight) {
            result =
                <div style={mergeStyles(Styles.rightContainer, Styles.container)}>
                    <div style={mergeStyles(Styles.statusWrapper, Styles.rightStatusWrapper)}>
                        {statusComponent}
                    </div>

                    <div style={Styles.tulipImageContainer} ref={(ref) => {this.tulip = ref}}>
                        <TulipImage
                            face={face}
                            gen={gen}
                            petal={petal}
                            stem={stem}
                            shadow={true}
                        />
                    </div>
                </div>;
        } else {
            result =
                <div style={mergeStyles(Styles.leftContainer, Styles.container)}>
                    <div style={Styles.tulipImageContainer} ref={(ref) => {this.tulip = ref}}>
                        <TulipImage
                            face={face}
                            gen={gen}
                            petal={petal}
                            stem={stem}
                            size={185}
                            shadow={true}
                        />
                    </div>

                    <div style={mergeStyles(Styles.statusWrapper, Styles.leftStatusWrapper)}>
                        {statusComponent}
                    </div>
                </div>;
        }

        return result;
    }

    componentDidMount() {
        let start = Date.now();
        setInterval(() => {
            let elapsed = Date.now() - start;
            let position = 3 * Math.cos(elapsed / 200) - 3;
            this.tulip.style.top = '' + position + 'px';
        }, 20);
    }
}
