import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: 16,
        maxWidth: 640,
        textAlign: 'center',
        marginTop: 16,
    },
    flex:{
        alignItems: 'center',
        margin: '24px 0',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
    },
    header: {
        fontSize: 32,
        marginBottom: 8,
    },
    metamaskButton: {
        backgroundColor: '#F92A82',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
        maxWidth: 128,
    },
};

export default class MetaMaskNux extends Component {
    render() {
        return (
            <div style={Styles.flex}>
                <div style={Styles.container}>
                    <div style={Styles.header}>MetaMask isn't installed.</div>
                    <br />
                    <div>EtherTulips needs MetaMask to connect to the Ethereum network and store your tulips.</div>
                    <br />
                    <div>MetaMask is an extension for the Google Chrome, Firefox, Opera, and Brave browsers that lets your browser interact with the Ethereum network. It's also where your tulips will be stored, so keep it safe!</div>
                    <br />
                    <div>Once you've installed MetaMask, refresh this page.</div>
                    <br />
                    <br />
                    <div>
                        <a href="https://metamask.io/" target="_blank" style={Styles.metamaskButton}>
                            Get MetaMask <span className="fa fa-chevron-right"></span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
