import React, { Component } from 'react';

import Strings from 'darwin/Strings';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    container: {
        borderTop: '2px solid #ddd',
        display: 'flex',
        flexDirection: 'row',
        padding: '32px 64px',
        fontSize: 14,
    },
    col1: {
        minWidth: 192,
    },
    col2: {
        minWidth: 192,
    },
    spacer: {
        flex: 1,
    },
    col3: {
        textAlign: 'right',
    },
};

export default class Footer extends Component {
    render() {
        return (
            <footer style={Styles.container}>
                <div style={Styles.col1}>
                    <a href="https://medium.com/@ethertulips/ether-tulips-decentralized-tulip-farming-and-battling-3f453a74810d">About</a><br />
                    <a href="/legal/attributions">Attributions</a><br />
                    <a href="/legal/terms">Terms of Use</a><br />
                    <a href="/legal/privacy">Privacy Policy</a><br />
                </div>
                <div style={Styles.col2}>
                    {/*<a href={Strings.socialRedditUrl} className="redditLink" target="_blank">Reddit</a><br />*/}
                    <a href={Strings.socialDiscordUrl} className="discordLink" target="_blank">Discord</a><br />
                    <a href={Strings.socialTwitterUrl} className="twitterLink" target="_blank">Twitter</a><br />
                    <a href={Strings.socialMediumUrl} className="blogLink" target="_blank">Blog</a><br />
                </div>
                <div style={Styles.spacer}></div>
                <div style={Styles.col3}>
                    <span>&copy; 2018-2021 EtherTulips. All rights reserved.</span><br />
                    <a href="mailto:contact@ethertulips.com" className="footerEmailLink">contact@ethertulips.com</a>
                </div>
            </footer>
        );
    }
}
