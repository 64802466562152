import {ethers} from 'ethers';

import getProvider from 'darwin/lib/web3/getProvider';

import TulipContract from 'contracts/v1/TulipCore.sol/TulipCore.json';

import {mapTulip} from './mappers';

const mainnetAddress = '0x995020804986274763dF9deb0296B754f2659cA1';
const localhostAddress = '0x5FbDB2315678afecb367f032d93F642f64180aa3';

class EtherTulipsApi {
    constructor() {
        // DON'T access these directly; use the provider() and account() promises
        // or, if you're feeling frisky, use maybeGetProvider() or maybeGetAccount()
        this._provider = null;
        this._account = null;
        this._readyPromise = this.initProvider()
            .then(() => this.loadAccount());
    }

    initProvider() {
        return getProvider()
            .then((provider) => {
                this._provider = provider;
            });
    }

    async loadAccount() {
        const signer = this._provider.getSigner();
        const address = await signer.getAddress();

        if (!address) {
            throw 'No connected account';
        }

        this._account = address;

        return this._account;
    }

    ready() {
        return this._readyPromise;
    }

    account() {
        return this.ready().then(() => this._account);
    }

    provider() {
        return this.ready().then(() => this._provider);
    }

    maybeGetAccount() {
        return this._account;
    }

    maybeGetProvider() {
        return this._provider;
    }

    getNumOwnedTulips() {
        return this._deployedContractWithAccount(TulipContract)
            .then(([acct, contr]) => {
                return contr.balanceOf(acct);
            });
    }

    getTulips() {
        let deployed, account;
        let tIds;
        return this._deployedContractWithAccount(TulipContract)
            .then(([acct, contr]) => {
                deployed = contr;
                account = acct;
                return deployed.myTulips();
            })
            .then(tulipIds => {
                tIds = tulipIds;
                return Promise.all(
                    tulipIds.map(id => deployed.getTulip(id.toNumber())),
                );
            })
            .then(res => {
                let tulipData = res.map(mapTulip);
                for (let i = 0; i < tulipData.length; i++) {
                    tulipData[i].id = tIds[i];
                }
                return tulipData;
            });
    }

    fetchOwnedTulipsBatch(offset, maxAmount) {
        let deployed, account, amountRemaining;
        let tIds;
        return this._deployedContractWithAccount(TulipContract)
            .then(([acct, contr]) => {
                deployed = contr;
                account = acct;
                return deployed.myTulipsBatched(offset, maxAmount);
            })
            .then(([tulipIds, remaining]) => {
                amountRemaining = remaining;
                tIds = tulipIds;
                return Promise.all(
                    tulipIds.map(id => deployed.getTulip(id.toNumber())),
                );
            })
            .then(res => {
                let tulipData = [res.map(mapTulip), amountRemaining];
                for (let i = 0; i < tulipData[0].length; i++) {
                    tulipData[0][i].id = tIds[i];
                }
                return tulipData;
            });
    }

    buyTulips(amount, gen, weiValue) {
        return this._deployedContractWithAccount(TulipContract)
            .then(([account, contr]) => {
                return contr.buyTulips(amount, gen, {value: weiValue.toString()});
            });
    }

    transferTulip(id, to) {
        return this._deployedContractWithAccount(TulipContract)
            .then(([account, contr]) => {
                return contr.transfer(to, id);
            });
    }

    getPriceForGen(gen) {
        return this._deployedContract(TulipContract)
            .then(contr => {
                return contr.price(gen);
            });
    }

    getPriceIncreaseDetailsForGen(gen) {
        return this._deployedContract(TulipContract)
            .then(contr => {
                return contr.nextPrice(gen);
            });
    }

    async _getContractAddress(provider) {
        const { chainId } = await provider.getNetwork();
        window.provider = provider;
        if (chainId == 1) {
            return mainnetAddress;
        } else if (chainId == 31337) {
            return localhostAddress;
        } else {
            throw "unsupported network"; // TODO: prompt the user to switch to mainnet
        }
    }

    async _deployedContract() {
        const provider = await this.provider();
        const contractAddress = await this._getContractAddress(provider);
        return new ethers.Contract(
            contractAddress,
            TulipContract.abi,
            provider,
        );
    }

    async _deployedContractWithAccount() {
        const [provider, account] = await Promise.all([this.provider(), this.account()]);
        const contractAddress = await this._getContractAddress(provider);
        const contract = new ethers.Contract(
            contractAddress,
            TulipContract.abi,
            provider.getSigner(),
        );

        return [account, contract];
    }
}

const api = new EtherTulipsApi();
window.etapi = api;
export default api;
