import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    splashContainer: {
        borderTop: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: 400,
        background: 'url("' + getAsset('floralblue.png') + '")',
    },
    splash: {
    },
    splashImage: {
        height: 250,
    },
    splashHead: {
    },
    splashSubhead: {
        fontSize: 'larger',
    },
    splashButton: {
        backgroundColor: '#F92A82',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
};

export default class LandingSplash extends Component {
    render() {
        return (
            <div style={Styles.splashContainer}>
                <div style={Styles.splashImageContainer}>
                    <img style={Styles.splashImage} src={getAsset("tulipexamples.png")} alt="Example tulip" />
                </div>
                <div style={Styles.splashContent}>
                    <h1 style={Styles.splashHead}>EtherTulips</h1>
                    <p style={Styles.splashSubhead}>
                        Tradable virtual tulips on the Ethereum blockchain that can fight each other. What could go wrong?
                    </p>
                    <p>
                        <a href="/shop" style={Styles.splashButton}>
                            Buy Now <span className="fa fa-chevron-right"></span>
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}
