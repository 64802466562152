import React, { Component } from 'react';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';
import StorageKey from 'darwin/lib/storage/StorageKey';
import DuelApi from 'darwin/lib/duel/DuelApi';
import Web3Api from 'darwin/lib/api';

import Duel from 'darwin/components/Duel';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';

var Styles = {
    loadMessage: {
        textAlign: 'center',
        margin: '100px 0',
        fontSize: 'larger',
    },
};

class DuelScene extends Component {
    static KEY = 'battles/duel/game';

    constructor(props) {
        super(props);
        this.duelApi = null;

        let tulipLoadout = JSON.parse(localStorage.getItem(StorageKey.TULIP_LOADOUT));
        let tulipPrimary = localStorage.getItem(StorageKey.TULIP_LOADOUT_PRIMARY);

        this.state = {
            ready: false,
            myIndex: -1,
            gameProps: null,
            gameState: null,
            tulipLoadout,
            tulipPrimary,
        };
    }

    componentWillMount() {
        document.title = 'EtherTulips: Duel';
    }

    async componentDidMount() {
        let address = await Web3Api.account();
        if (this.state.tulipLoadout && this.state.tulipPrimary) {
            this.duelApi = new DuelApi(address, this.state.tulipLoadout, this.state.tulipPrimary, this);
        } else {
            this.props.history.push(
                '/battles/duel/loadout',
            );
        }
    }

    render() {
        if (this.state.ready) {
            return (
                <Duel
                    myIndex={this.state.myIndex}
                    gameProps={this.state.gameProps}
                    gameState={this.state.gameState}
                    handler={this}
                    ref={instance => { this.component = instance }}
                />
            );
        } else {
            return (
                <div style={Styles.loadContainer}>
                    <div style={Styles.loadMessage}>
                        Please wait while we match you with an opponent...
                    </div>

                    <SceneLoadingIndicator />
                </div>
            );
        }
    }

    handleInit(gameProps, gameState, myIndex) {
        this.setState({
            ready: true,
            myIndex: myIndex,
            gameProps: gameProps,
            gameState: gameState,
        });
    }

    handleUpdate(gameState) {
        this.setState({
            gameState: gameState,
        });
        this.component.handleUpdate();
    }

    handleMove(moveIndex) {
        this.duelApi.move(moveIndex);
    }

    handleSwitch(tulipIndex) {
        this.duelApi.swap(tulipIndex);
    }

    handleSendChat(message) {
        this.duelApi.sendChat(message);
    }
}

export default etherTulipsPagify(sceneRequiresApi(DuelScene));
