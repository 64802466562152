import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';
import RarityLevels from 'darwin/lib/constants/RarityLevels';

import TulipImage from 'darwin/components/TulipImage';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tulipContainer: {
        backgroundColor: '#eee',
        borderRadius: 8,
        position: 'relative',
        paddingTop: 20,
    },
    tulipBpOverlay: {
        position: 'absolute',
        top: 8,
        right: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tulipRarityOverlay: {
        position: 'absolute',
        top: 8,
        left: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tulipSendOverlay: {
        position: 'absolute',
        bottom: 8,
        right: 8,
        height: 16,
    },
    captionContainer: {
        textAlign: 'center',
        fontSize: 'smaller',
    },
    speciesCaption: {
        fontSize: 'larger',
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
};

export default class TulipCell extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mouseOver: false,
        };
    }

    render() {
        let caption, image, infoOnClick, sendOnClick;
        let rarityView = null;
        let bpView = null;
        let sendIcon = null;

        if (this.props.loading) {
            caption =
                <div>
                    <div style={Styles.speciesCaption}>Loading</div>
                    <div>...</div>
                </div>;
            image =
                <TulipImage
                    loading={true}
                    petal={null}
                    stem={null}
                    face={null}
                />;

        } else {
            let petal = this.props.appearance[0];
            let stem = this.props.appearance[1];
            let face = this.props.appearance[2];
            let rarity = this.props.appearance[3];
            let species = this.props.appearance[4];
            let gen = this.props.appearance[5];

            let bp = this.props.battleStats[0];
            let battleStats = this.props.battleStats.slice(1);

            let rarityStyle = {
                color: RarityLevels[rarity].color,
            };

            let rarityName = RarityLevels[rarity].name;

            if (rarity == 8) {
                rarityStyle = mergeStyles(rarityStyle, { textShadow: '1px 1px #FFFF00' });
                rarityName =
                    <span>
                        &#127770; {rarityName} &#127770;
                    </span>;
            }

            infoOnClick = () => this.props.onClick && this.props.onClick(this.props.tulip, this.props.appearance, this.props.battleStats);
            sendOnClick = () => this.props.onSendClick && this.props.onSendClick(this.props.tulip.id, this.props.appearance);

            rarityView = (
                <div style={rarityStyle}>{rarityName}</div>
            );
            bpView = (
                <span>BP {bp}</span>
            );
            caption =
                <div>
                    <div style={Styles.speciesCaption}>{species}</div>
                    <div>Gen {gen}</div>
                </div>;
            image =
                <TulipImage
                    loading={false}
                    petal={petal}
                    stem={stem}
                    face={face}
                    gen={gen}
                />;
            sendIcon = (
                <div style={Styles.tulipSendOverlay}>
                    <span style={{fontSize: 14}}><span className="fa fa-star" style={{color: '#ff0000'}}></span> Starter</span>
                </div>
            );
        }

        return (
            <div
                style={Styles.container}
                onMouseOver={() => this.setState({ mouseOver: true })}
                onMouseLeave={() => this.setState({ mouseOver: false })}
            >
                <div style={mergeStyles(Styles.tulipContainer, this.props.selected && {backgroundColor: '#ffff66'})}>
                    <div onClick={infoOnClick}>
                        {image}
                    </div>
                    <div style={Styles.tulipBpOverlay} onClick={infoOnClick}>
                        {bpView}
                    </div>
                    <div style={Styles.tulipRarityOverlay}>
                        {rarityView}
                    </div>
                    {this.props.isStarter && sendIcon}
                </div>
                <div style={Styles.captionContainer}>
                    {caption}
                </div>
            </div>
        );
    }
}
