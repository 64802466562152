import React, { Component } from 'react';

import Api from 'darwin/lib/api';

import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';

import MyTulipsScene from 'darwin/scenes/MyTulipsScene';
import ShopScene from 'darwin/scenes/ShopScene';
import MarketplaceScene from 'darwin/scenes/MarketplaceScene';
import BattleScene from 'darwin/scenes/BattleScene';
import DuelMainMenuScene from 'darwin/scenes/DuelMainMenuScene';
import DuelLoadoutScene from 'darwin/scenes/DuelLoadoutScene';
import DuelScene from 'darwin/scenes/DuelScene';

var Styles = {
    container: {
        display: 'flex',
        paddingLeft: 32,
        paddingRight: 32,
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logoImage: {
        width: 32,
        height: 32,
        marginRight: 8,
        marginTop: -4,
    },
    logoLink: {
        textDecoration: 'none',
        color: '#000',
    },
    logoName: {
        fontSize: 24,
    },
    spacer: {
        flex: 1,
        minWidth: 24,
    },
    metamaskStatus: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 24,
    },
    metamaskStatusBox: {
        display: 'flex',
        alignItems: 'center',
        border: '2px solid',
        borderRadius: 18,
        height: 24,
        padding: '4px 8px',
        fontSize: 12,
    },
    navContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    navItemMyTulipsSelected: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '2px solid #03c03c',
    },
    navItemMyTulipsSelectedText: {
        color: '#03c03c',
    },
    navItemShopSelected: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '2px solid #779ecb',
    },
    navItemShopSelectedText: {
        color: '#779ecb',
    },
    navItemMarketplaceSelected: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '2px solid #b19cd9',
    },
    navItemMarketplaceSelectedText: {
        color: '#b19cd9',
    },
    navItemBattleSelected: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '2px solid #ff3366',
    },
    navItemBattleSelectedText: {
        color: '#ff3366',
    },
    navItem: {
        color: '#000',
        textDecoration: 'none',
    },
    navSpacer: {
        width: 24,
    },
};

const API_STATUS_LOADING = 0;
const API_STATUS_LOADED = 1;
const API_STATUS_FAILED = -1;

export default class Header extends Component {
    constructor(props){
        super(props);

        this.state = {
            apiStatus: API_STATUS_LOADING,
        };
    }

    componentWillMount() {
        Api.ready()
            .then(() => {
                this.setState({apiStatus: API_STATUS_LOADED});
            })
            .catch(() => {
                this.setState({apiStatus: API_STATUS_FAILED});
            });
    }

    render() {
        return (
            <div style={Styles.container}>
                <a href="/" style={Styles.logoLink}>
                    <div style={Styles.logoContainer}>
                            <img src={getAsset('logo.png')} style={Styles.logoImage} />
                            <h1 style={Styles.logoName}>EtherTulips</h1>
                    </div>
                </a>
                <div style={Styles.spacer}>
                </div>
                <div style={Styles.metamaskStatus}>
                    {this._renderMetamaskIndicator()}
                </div>
                <nav style={Styles.navContainer}>
                    <div style={mergeStyles(this.props.pageKey == MyTulipsScene.KEY && Styles.navItemMyTulipsSelected)}>
                        <a href="/my_tulips" style={mergeStyles(Styles.navItem, this.props.pageKey == MyTulipsScene.KEY && Styles.navItemMyTulipsSelectedText)}>My Tulips</a>
                    </div>
                    <div style={Styles.navSpacer}></div>
                    <div style={mergeStyles(this.props.pageKey == ShopScene.KEY && Styles.navItemShopSelected)}>
                        <a href="/shop" style={mergeStyles(Styles.navItem, this.props.pageKey == ShopScene.KEY && Styles.navItemShopSelectedText)}>Shop</a>
                    </div>
                    {/*<div style={Styles.navSpacer}></div>*/}
                    {/*<div style={mergeStyles(this.props.pageKey == MarketplaceScene.KEY && Styles.navItemMarketplaceSelected)}>*/}
                    {/*    <a href="/marketplace" style={mergeStyles(Styles.navItem, this.props.pageKey == MarketplaceScene.KEY && Styles.navItemMarketplaceSelectedText)}>Marketplace</a>*/}
                    {/*</div>*/}
                    <div style={Styles.navSpacer}></div>
                    <div style={mergeStyles((this.props.pageKey == DuelScene.KEY || this.props.pageKey == DuelMainMenuScene.KEY || this.props.pageKey == DuelLoadoutScene.KEY) && Styles.navItemBattleSelected)}>
                        <a href="/battles/duel" style={mergeStyles(Styles.navItem, (this.props.pageKey == DuelScene.KEY || this.props.pageKey == DuelMainMenuScene.KEY || this.props.pageKey == DuelLoadoutScene.KEY) && Styles.navItemBattleSelectedText)}>Battle</a>
                    </div>
                </nav>
            </div>
        );
    }

    _renderMetamaskIndicator() {
        let borderColor = null;
        let inner = null;
        switch (this.state.apiStatus) {
            case API_STATUS_LOADING:
                inner = (
                    <span><span className="fa fa-spinner" style={{color: '#555'}}></span> MetaMask loading...</span>
                );
                borderColor = '#999';
                break;
            case API_STATUS_LOADED:
                inner = (
                    <span><span className="fa fa-user" style={{color: 'green'}}></span> {Api.maybeGetAccount().substring(0, 16) + '...'}</span>
                );
                borderColor = '#999';
                break;
            default:
                inner = (
                    <span><span className="fa fa-exclamation-triangle" style={{color: 'red'}}></span> MetaMask not installed.</span>
                );
                borderColor = 'red';
                break;
        }
        return (
            <div style={mergeStyles(Styles.metamaskStatusBox, {borderColor: borderColor})}>
                {inner}
            </div>
        );
    }
}
